import { BookOutlined }                      from '@ant-design/icons';
import { CrownOutlined }                     from '@ant-design/icons';
import { HomeOutlined }                      from '@ant-design/icons';
import { TeamOutlined }                      from '@ant-design/icons';
import { UserOutlined }                      from '@ant-design/icons';
import AdminUserModel                        from './models/services/admin/AdminUserModel';
import SubscriptionModel                     from './models/services/account/SubscriptionModel';
import AccountModel                          from './models/services/account/AccountModel';
import UserModel                             from './models/services/account/UserModel';

export default [
	{ label: 'Accueil', icon: <HomeOutlined />, link: '/' },
	{
		label: AccountModel.localize('plural'),
		link: AccountModel.pagePath('list'),
		icon: <TeamOutlined />,
	},
	{
		label: UserModel.localize('plural'), 
		link: UserModel.pagePath('list'),
		icon: <UserOutlined />,
	},
	{
		label: AdminUserModel.localize('plural'), 
		link: AdminUserModel.pagePath('list'),
		icon: <CrownOutlined />,
	},
	{
		label: SubscriptionModel.localize('plural'), 
		link: SubscriptionModel.pagePath('list'),
		icon: <BookOutlined />,
	},
];

export const navigationSettings = [];
